/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import Header from "./Header";
import SocialButton from "./SocialButton";
import AboutSection from "./AboutSection";
import HowToBuySection from "./HowToBuySection";
import TokenomicsSection from "./TokenomicsSection";
import Footer from "./Footer";

const KhabyLameMain: React.FC = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const howToBuyRef = useRef<HTMLDivElement>(null);
  const tokenomicRef = useRef<HTMLDivElement>(null);

  const sectionRefs = {
    aboutRef,
    howToBuyRef,
    tokenomicRef,
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToSection = (ref: any) => {
    if (ref.current) {
      const elementPosition =
        ref.current.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - 35;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = (item: string) => {
    switch (item) {
      case "about":
        scrollToSection(sectionRefs.aboutRef);
        break;
      case "how-to-buy":
        scrollToSection(sectionRefs.howToBuyRef);
        break;
      case "tokenomics":
        scrollToSection(sectionRefs.tokenomicRef);
        break;
      default:
        scrollToTop();
        break;
    }
  };

  return (
    <div className="flex overflow-hidden flex-col bg-yellow-400">
      <Header />
      <div className="flex relative flex-col px-20 pt-14 pb-[300px] w-full min-h-[916px] rounded-[40px] max-md:px-5 max-md:pb-32 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6edf79b3029f016926bbc5befd7aa8cb7c179ac44aef34d8c67d6aa59aeb89b7?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
          alt=""
          className="object-cover absolute inset-0 size-full"
        />
        <div className="flex relative flex-col items-center max-md:max-w-full">
          <h1 className="text-5xl leading-tight text-yellow-400 uppercase border-black border-solid border-[5px] max-md:max-w-full max-md:text-4xl border-transparent text-ageer-black">
            Easy Meme for you, Why make it complicated?
          </h1>
          <div className="flex flex-col items-center w-full max-w-[1260px] max-md:max-w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4eca7ef1e5473f7401484ebd88af46bdfcd888f723a11491792103027f1bbbe7?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
              alt="Khaby Lame meme"
              className="object-contain max-w-full aspect-[1.14] shadow-[0px_0px_16px_rgba(255,207,85,1)] shadow-none w-[474px]"
            />
            <div className="flex flex-wrap gap-6 justify-center items-center px-24 py-8 w-full bg-purple-600 rounded-2xl border-4 border-black border-solid max-md:px-5">
              <div className="flex flex-col grow shrink justify-center self-stretch my-auto gap-4 min-w-[240px] w-[451px] max-md:max-w-full">
                <h2 className="self-start text-5xl leading-tight text-yellow-400 uppercase border border-solid border-white border-opacity-50 !border-transparent max-md:max-w-full max-md:text-4xl">
                  Khaby Lame on Base
                </h2>
                <div className="flex flex-wrap justify-between items-center px-6 py-2.5 w-full bg-purple-400 rounded-xl max-w-[551px] max-md:px-5 max-md:max-w-full">
                  <div className="self-stretch my-auto text-2xl tracking-widest leading-8 text-amber-300 w-[calc(100%-40px)]">
                    $KBL Address: <br />
                    <div className="truncate md:w-auto">Coming soon</div>
                  </div>
                  <div className="flex overflow-hidden gap-2.5 justify-center items-center self-stretch py-px pr-1 pl-0.5 my-auto w-10 min-h-[40px]">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc3487c327cda81ba8cea5728c3a2d1ddf4422aa37d63a06ee57b6e66b06ed96?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
                      alt="Copy contract"
                      className="object-contain self-stretch my-auto aspect-[0.9] w-[35px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col grow shrink justify-around items-center self-stretch text-base text-center gap-4 text-white whitespace-nowrap min-w-[240px] w-[402px] max-md:max-w-full">
                <div className="flex flex-col justify-center items-center w-full max-w-[521px] max-md:max-w-full">
                  <div className="flex flex-wrap flex-1 gap-5 justify-center items-center rounded-xl max-w-[1200px] size-full max-md:max-w-full">
                    <div className="flex flex-wrap grow shrink gap-4 items-start self-stretch min-w-[240px] w-[394px] max-md:max-w-full">
                      <SocialButton
                        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/312210ed65a8177cbc06ceb2b05ca7a231cd6032ba62cdbcb24982ae8f6aa2f3?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
                        text="Twitter"
                      />
                      <SocialButton
                        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/4ee84a661ca9b5884b674b6572faa08c3b0684930c57c3819a8c3a0599a68b3f?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
                        text="Telegram"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center w-full max-w-[521px] max-md:max-w-full">
                  <div className="flex flex-wrap flex-1 gap-5 justify-center items-center rounded-xl max-w-[1200px] size-full max-md:max-w-full">
                    <div className="flex flex-wrap grow shrink gap-4 items-start self-stretch min-w-[240px] w-[394px] max-md:max-w-full">
                      <SocialButton
                        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/3a124768142fb0896ee494bbd17e4dd27fd230b6626e15685f02af6d48d76c79?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
                        text="Dextools"
                      />
                      <SocialButton
                        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/2a2af95cc69bb26793a9a7ef8c3f0b6fcb0cda6fc2763eb1325ddac485f53431?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
                        text="DexScreener"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="fixed bottom-10 z-30 flex flex-wrap gap-10 justify-between items-center px-24 py-4 mt-10 mb-0 w-full text-2xl tracking-widest text-black uppercase bg-amber-500 border-black border-solid border-[6px] min-h-[95px] rounded-[58px] max-w-[1260px] mx-auto max-md:px-5 max-md:mb-2.5 max-md:max-w-full max-md:gap-6 max-md:justify-center max-lg:static">
            <a
              onClick={() => handleScroll("home")}
              className="gap-2.5 self-stretch px-9 py-4 my-auto whitespace-nowrap max-md:px-5 max-md:py-0 header-custom cursor-pointer"
            >
              Home
            </a>
            <a
              onClick={() => handleScroll("about")}
              className="gap-2.5 self-stretch px-9 py-4 my-auto max-md:px-5 max-md:py-0 header-custom cursor-pointer"
            >
              About KBL
            </a>
            <a
              onClick={() => handleScroll("how-to-buy")}
              className="gap-2.5 self-stretch px-9 py-4 my-auto max-md:px-5 max-md:py-0 header-custom cursor-pointer"
            >
              How to buy
            </a>
            <a
              onClick={() => handleScroll("tokenomics")}
              className="gap-2.5 self-stretch px-9 py-4 my-auto whitespace-nowrap max-md:px-5 max-md:py-0 header-custom cursor-pointer"
            >
              Tokenomics
            </a>
          </nav>
        </div>
      </div>
      <AboutSection ref={aboutRef} />
      <HowToBuySection ref={howToBuyRef} />
      <TokenomicsSection ref={tokenomicRef} />
      <section className="flex z-0 flex-col w-full max-md:max-w-full">
        <div className="flex overflow-hidden flex-col items-end px-20 pt-16 w-full text-5xl text-black uppercase leading-[62px] max-md:pl-5 max-md:max-w-full max-md:text-4xl max-md:pr-0 max-md:leading-[53px]">
          <div className="flex relative flex-col pt-16 min-h-[665px] max-w-[1260px] mx-auto max-md:pr-5 max-md:max-w-full max-md:text-4xl max-md:leading-[53px] max-md:min-h-0 max-md:pt-0">
            <div className="header-custom mb-[-185px] pr-[39%] max-md:text-2xl max-md:mb-0 max-md:pr-0">
              Don't need to make it fucking complicated. Buy $KBL and take
              profit!
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f0e1a22ee6fb51d625a5d8adfd9f189b502d9e0842653d9f5ede1e5450639fe7?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
              alt=""
              className="object-cover inset-0 h-full w-full"
            />
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default KhabyLameMain;
