import React from "react";

interface SocialButtonProps {
  icon: string;
  text: string;
}

const SocialButton: React.FC<SocialButtonProps> = ({ icon, text }) => {
  const handleRedirect = () => {
    let link = "";
    switch (text) {
      case "Twitter":
        link = "https://x.com/KhabyMemeCoin";
        break;
      case "Telegram":
        link = "https://t.me/khaby_lame_coin_portal";
        break;
      case "Dextools":
        link = "https://www.dextools.io";
        break;
      case "DexScreener":
        link = "https://dexscreener.com/";
        break;
      default:
        break;
    }
    link && window.open(link, "_blank", "noopener,noreferrer");
  };
  
  return (
    <button
      onClick={handleRedirect}
      className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-6 py-4 rounded-2xl border-amber-300 border-solid basis-0 bg-yellow-500 bg-opacity-50 border-[3px] max-md:px-5 hover-slideDown"
    >
      <img
        loading="lazy"
        src={icon}
        alt={`${text} icon`}
        className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
      />
      <span className="self-stretch my-auto">{text}</span>
    </button>
  );
};

export default SocialButton;
